import { useCallback, useContext, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { MainContext } from '@ezbobdev/ui-framework';
import { MaterialAutocomplete, MaterialGrid, MaterialTextField } from '@ezbobdev/ui-widgets-ezbob';

const AddressAutocomplete = ({ houseNumberFieldName = '' }) => {
  const [cityResults, setCityResults] = useState([]);
  const [streetResults, setStreetResults] = useState([]);
  const { actions, sdkActions } = useContext(MainContext);

  const cityValue = actions.case.getCaseValue('applicant.address.town');
  const addressValue = actions.case.getCaseValue('applicant.address.line1');

  const searchCity = useCallback(
    async (e) => {
      if (e.target.value === '') {
        setCityResults([]);

        return;
      }

      let cityValues = [];

      try {
        const addresses = await sdkActions.integration.getAddressByParams({ city: e.target.value.trim() });
        if (addresses.content) {
          cityValues = addresses.content.map((address) => {
            return { value: address.town };
          });
        }
      } catch (e) {
        console.error(e);
      }

      setCityResults(cityValues);
    },
    [sdkActions.integration],
  );

  const searchStreet = useCallback(
    async (e) => {
      const query = { city: cityValue, street: e.target.value.trim() };

      if (e.target.value === '') {
        setStreetResults([]);

        return;
      }

      let streetValues = [];

      try {
        const addresses = await sdkActions.integration.getAddressByParams(query);
        if (addresses.content) {
          streetValues = addresses.content.map((address) => {
            return { value: address.line1 };
          });
        }
      } catch (e) {
        console.error(e);
      }
      setStreetResults(streetValues);
    },
    [cityValue, sdkActions.integration],
  );

  const handleCityChange = useMemo(() => debounce(searchCity, 1000, { leading: false, trailing: true }), [searchCity]);

  const handleStreetChange = useMemo(
    () => debounce(searchStreet, 1000, { leading: false, trailing: true }),
    [searchStreet],
  );

  return (
    <>
      <MaterialAutocomplete
        name="applicant.address.town"
        values={cityResults}
        MUIAutocompleteProps={{ value: cityValue || null }}
        onInputChange={(e, value, reason) => {
          if (reason === 'input') {
            handleCityChange(e);
          }
          if (reason === 'clear') {
            setCityResults([]);
          }
        }}
        className="widget"
        fullWidth
      />
      <MaterialGrid container spacing={2}>
        <MaterialGrid item xs={houseNumberFieldName ? 9 : 12}>
          <MaterialAutocomplete
            MUIAutocompleteProps={{ disabled: !cityValue, value: addressValue || null }}
            name="applicant.address.line1"
            values={streetResults}
            onInputChange={(e, value, reason) => {
              if (reason === 'input') {
                handleStreetChange(e);
              }
              if (reason === 'clear') {
                setStreetResults([]);
              }
            }}
            className="widget"
            fullWidth={!houseNumberFieldName}
          />
        </MaterialGrid>

        {houseNumberFieldName && (
          <MaterialGrid item xs={3}>
            <MaterialTextField name={houseNumberFieldName} className="widget" />
          </MaterialGrid>
        )}
      </MaterialGrid>
    </>
  );
};

export default AddressAutocomplete;
