import React, { useContext } from 'react';

import { MainContext, useTranslation } from '@ezbobdev/ui-framework';
import { MaterialGrid, MaterialTypography } from '@ezbobdev/ui-widgets-ezbob';

const InfoBox = (props) => {
  const { label, className, variant = 'body2', textColor } = props;
  const { t } = useTranslation();
  const { actions } = useContext(MainContext);

  const infoIcon = actions.settings.getUserConfigProp('assets.infoIcon.src');
  const iconAltText = props.icon?.altText ?? t('assets.infoIcon.alt');
  const iconSrc = props.icon?.src ?? infoIcon;

  return (
    <MaterialGrid
      container
      direction="row"
      gap={1}
      wrap="nowrap"
      alignItems="center"
      className={`InfoBox ${className}`}
    >
      <MaterialGrid container flex={0} alignSelf="start">
        {iconSrc && <img alt={iconAltText} src={iconSrc} />}
      </MaterialGrid>
      <MaterialGrid item>
        <MaterialTypography color={textColor} variant={variant}>
          {label}
        </MaterialTypography>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default InfoBox;
