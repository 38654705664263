import { useContext } from 'react';

import { MainContext, useTranslation } from '@ezbobdev/ui-framework';
import { css, styled } from '@ezbobdev/ui-widgets-ezbob';

import { PageWrapper } from '../../widgets';

const Wrapper = styled(PageWrapper)`
  ${({ theme, isFinal, backButton }) => {
    return css`
      .ScreenWrapper__header,
      .ScreenWrapper__custom-header {
        .ScreenWrapper__header__subtitle {
          margin-top: ${theme.spacing(1)};
        }
      }

      .ScreenWrapper__inner {
        text-align: center;

        .ScreenWrapper__top-bar {
          .MaterialBack {
            text-decoration: none;

            &:focus {
              text-decoration: underline;
            }
          }
        }

        .ScreenWrapper__inner-container {
          padding: ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(5)};
        }

        .MuiInputBase-root {
          .MuiSelect-select {
            text-align: left;
            padding-right: ${theme.spacing(2)};
          }
        }
      }

      .ScreenWrapper__top-image {
        max-width: 8rem;
        margin: 0 auto ${theme.spacing(4)};
      }

      .ScreenWrapper__header {
        margin: 0 auto ${theme.spacing(4)};
      }

      .ScreenWrapper__actions {
        margin: ${theme.spacing(2)} 0;
        align-items: end;
        flex-direction: column;
        gap: ${theme.spacing(2)};

        .MaterialButtonWrapper {
          width: 100%;
        }

        .disclaimer {
          color: ${theme.palette.grey.main};
        }
      }

      .ScreenWrapper__footer {
        display: none;
      }

      .MuiFormControl-root .MuiFormControlLabel-root {
        align-items: center;
        text-align: start;
        line-height: 1;

        .MuiButtonBase-root {
          margin-top: 0;
        }
      }

      ${theme.breakpoints.up('sm')} {
        .ScreenWrapper__progress-bar {
          border-radius: 0.8rem 0.8rem 0 0;
          padding: 0;

          .MuiStepConnector-root {
            right: calc(50% + 30px);
            left: calc(-50% + 30px);
          }
        }

        .ScreenWrapper__header {
          max-width: 53.6rem;
        }

        .ScreenWrapper__inner {
          background-position: left top;
          background-size: auto;
          max-width: 102.8rem;
          height: 80rem;
          padding: unset;

          .ScreenWrapper__inner-container {
            padding: 0 ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)};

            &.progressBar {
              padding: ${backButton?.show || isFinal ? 0 : theme.spacing(2)} 0 ${theme.spacing(3)};
              min-height: 20rem;
            }

            .ScreenWrapper__content {
              height: 100%;
              margin: 0 auto;
              width: 43.8rem;

              .MaterialIDV {
                height: 100%;
              }
            }

            .ScreenWrapper__actions {
              margin: ${theme.spacing(2)} auto 0;
              width: 43.8rem;
            }

            #idv-iframe {
              min-height: 100%;
            }

            .ScreenWrapper__footer {
              display: block;
              margin-top: ${theme.spacing(3)};
            }
          }

          .ScreenWrapper__top-bar {
            padding: 0 ${theme.spacing(5)};
            margin-bottom: unset;
          }
        }
      }

      ${isFinal &&
      css`
        .ScreenWrapper__inner .ScreenWrapper__inner-container {

          > :first-child {
            margin-top: ${theme.spacing(28)};
          }

          > .ScreenWrapper__actions {
            margin-top: auto;
            flex-grow: 0;
          }
        }
      `}
    `;
  }}
`;

const MorningPageWrapper = (props) => {
  const { t, i18n } = useTranslation();
  const { actions } = useContext(MainContext);
  const currentStepName = actions.userState.getCurrentStepName();
  const { footerImageSrc } = actions.settings.getUserConfigProp(`custom.${currentStepName}`) ?? {};

  return (
    <Wrapper
      footer={
        footerImageSrc && (
          <img
            src={footerImageSrc}
            alt={i18n.exists(`${currentStepName}.footerAlt`) ? t(`${currentStepName}.footerAlt`) : ''}
          />
        )
      }
      {...props}
    />
  );
};

export default MorningPageWrapper;
