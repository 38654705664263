import { useTranslation } from '@ezbobdev/ui-framework';
import {
  MaterialButton,
  MaterialDialog,
  MaterialDialogActions,
  MaterialDialogContent,
  MaterialDialogTitle,
  MaterialTypography,
} from '@ezbobdev/ui-widgets-ezbob';

import { Divider } from '../index';

const GroupCodePopup = ({ popupIcon, stepName, onClose, offerCode, open }) => {
  const { t } = useTranslation();

  const handleLinkClick = (evt) => {
    if (evt.target.tagName === 'A' && evt.target.getAttribute('data-target')) {
      onClose();
    }
  };

  return (
    <MaterialDialog variant="medium" open={open}>
      <MaterialDialogTitle
        disableTypography
        icon={<img src={popupIcon} alt={t(`${stepName}.popupIconAlt`)} className="dialogTitleIcon" />}
        onClose={onClose}
        withDivider
      >
        <MaterialTypography variant="h1">{t(`globals.offersCodes.${offerCode}.popup.title`)}</MaterialTypography>
      </MaterialDialogTitle>

      <MaterialDialogContent>
        <MaterialTypography variant="body1" onClick={handleLinkClick}>
          {t(`globals.offersCodes.${offerCode}.popup.body`)}
        </MaterialTypography>
      </MaterialDialogContent>

      <MaterialDialogActions>
        <Divider />
        <MaterialButton fullWidth onClick={onClose}>
          {t(`${stepName}.groupCodePopupButtonCloseText`)}
        </MaterialButton>
      </MaterialDialogActions>
    </MaterialDialog>
  );
};

export default GroupCodePopup;
