import { helpType, stepsNames } from '../utils/constants';
import enLanguage from './languages/en-GB';
//Lists
import loanPurposeList_LIMITED_COMPANY from './lists/loanPurpose_LIMITED_COMPANY';
import loanPurposeList_SOLE_TRADER from './lists/loanPurpose_SOLE_TRADER';
import tradingActivityList from './lists/tradingActivity';
import typeOfBusiness from './lists/typeOfBusiness';
import theme from './theme';

/*
 * validatorjs docs - https://github.com/skaterdav85/validatorjs
 * Cleave.js - https://github.com/nosir/cleave.js/blob/master/doc/options.md
 * moment durations - https://momentjs.com/docs/#/durations/
 * */

const locals = {
  primaryDateFormat: 'DD/MM/YYYY',
  secondaryDateFormat: 'Do MMMM, YYYY',
  phoneMask: '... ... ....',
  currency: 'GBP', // https://www.currency-iso.org/dam/downloads/lists/list_one.xml,
  numeral: true,
  numeralPositiveOnly: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  numeralIntegerScale: 15,
  numeralDecimalScale: 2,
  numeralDecimalMark: '.',
  delimiter: ',',
};

const currencyFormattingOptions = {
  type: 'number',
  numeral: locals.numeral,
  numeralPositiveOnly: locals.numeralPositiveOnly,
  maximumFractionDigits: locals.maximumFractionDigits,
  minimumFractionDigits: locals.minimumFractionDigits,
  numeralIntegerScale: locals.numeralIntegerScale,
  numeralDecimalScale: locals.numeralDecimalScale,
  numeralDecimalMark: locals.numeralDecimalMark,
  delimiter: locals.delimiter,
};

const positiveIntegerFormattingOptions = {
  numeral: true,
  numeralPositiveOnly: true,
  numeralDecimalMark: '',
  delimiter: '',
  numeralDecimalScale: 0,
};

const resourcesCdnBasePath = 'https://code.ezbob.com/app-ezx/resources/';

const cfg = {
  tenantData: {
    tenantName: 'BeBank',
    tenantFullName: 'BeBank LTD.',
    showIdentifier: false,
    useTenantBranding: true,
    theme,
    assets: {
      logo: {
        src: `${resourcesCdnBasePath}svg/BeBank_logo.svg`,
        alt: 'BeBank logo',
        height: '3.2rem',
      },
      icon: {
        src: `${resourcesCdnBasePath}svg/BeBank_icon.svg`,
        alt: 'BeBank icon',
      },
    },
    products: {
      flow: [
        {
          name: 'TERM_LOANS_EU',
          url: 'https://ui.qa1.bebank.ezbob.com/customer/wizard',
        },
        {
          name: 'TERM_LOANS_US',
          url: 'https://ui.qa1.bebank-us.ezbob.com/customer/wizard',
        },
        {
          name: 'NEW_JOINT_ACCOUNT',
          url: 'https://ui-qa1.bebank-jointaccount.ezbob.com/customer/wizard',
        },
        {
          name: 'MWC_UK_APPROVE',
          url: 'http://ui-qa1.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"1","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"London","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_DE_APPROVE',
          url: 'https://ui-qa1-eu.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"1","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"Berlin","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_IT_APPROVE',
          url: 'https://ui-qa1-eu.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"1","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"Italy","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_UK_REJECT',
          url: 'http://ui-qa1.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"3","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"London","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_DE_REJECT',
          url: 'https://ui-qa1-eu.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"3","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"Berlin","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_IT_REJECT',
          url: 'https://ui-qa1-eu.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"3","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"Italy","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_UK_REFER',
          url: 'http://ui-qa1.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"2","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"London","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_DE_REFER',
          url: 'https://ui-qa1-eu.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"2","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"Berlin","offerSuiteId":"offerSuiteIdnova123"}',
        },
        {
          name: 'MWC_IT_REFER',
          url: 'https://ui-qa1-eu.mwc.ezbob.com/customer/wizard/customer/wizard?code={"code":"2","userId":"userid123","userECID":"userECIDnova123","businessECID":"businessECIDnova123","accountID":"Italy","offerSuiteId":"offerSuiteIdnova123"}',
        },
      ],
      support: [
        {
          name: 'IDV',
          url: 'https://ui.qa1.bebank-idv.ezbob.com/customer/wizard',
        },
        {
          name: 'OCR',
          url: 'https://ui-qa1.bebank-ocr.ezbob.com/customer/wizard',
        },
      ],
      backOffice: [
        {
          name: 'BACKOFFICE_PORTAL',
          url: 'https://ezbob--bbusqa.sandbox.my.salesforce.com/',
        },
        {
          name: 'ADMIN',
          url: 'https://admin-express-qa1.tools.ezbob.com/apps/admin/Flows',
        },
      ],
    },
  },
  system: {
    allowInactiveCases: false,
    enableDeviceFraudCheck: true,
    journeyStrategy: 'url',
    vendorName: 'EZX',
    uiVersion: '1.0.0',
    inactiveLogout: { minutes: 10, redirectUrl: '/customer/timeout' }, // Use moment docs for formatting -  https://momentjs.com/docs/#/durations/
    checkFraud: false,
    requireLogin: false,
    disableNewCases: false,
    allowSignup: true,
    openLinksInIframe: false,
    emailVerification: {
      validate: false,
      validateOnStep: 'congratulations',
    },
    providers: { openBanking: 'accountScore' },
  },
  externalServices: {
    datadog: {
      enabled: true,
      options: {
        clientToken: 'pubd83c125d45d03abc6e7aab662783f2cf',
        applicationId: 'b0cb9daf-5983-487a-b0e8-ed44ad696a43',
        site: 'datadoghq.com',
        service: 'app-ezx',
        env: window.location.host,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackViewsManually: true,
        allowedTracingUrls: [
          /^https?:\/\/api2\.[a-zA-Z0-9]+\.express\.ezbob\.com/,
          /^https?:\/\/api2\.([a-zA-Z0-9]+\.)?businessloans\.ezbobplatform\.co\.uk/,
        ],
      },
    },
  },
  locals: { ...locals },
  product: {
    loanType: 'business loan',
  },
  pageRoutes: {
    customerWizard: {
      name: 'customerWizard',
      url: '/customer/wizard',
      component: 'CustomerWizard',
    },
    customerAcceptInvitation: {
      name: 'acceptInvitation',
      url: '/customer/accept-invitation',
      excludeModules: ['ProgressBar'],
      title: 'pageRoutes.idVerification',
    },
    customerLogin: {
      name: 'customerLogin',
      url: '/customer/login',
      excludeModules: ['ProgressBar'],
      title: 'pageRoutes.customerLogin',
      component: 'CustomerLogin',
    },
    customerSessionTimeout: {
      url: '/customer/timeout',
      excludeModules: ['ProgressBar'],
      title: 'pageRoutes.customerSessionTimeout',
      component: 'SessionTimeout',
      pageActions: [
        {
          type: 'redirect',
          url: '/customer/login',
        },
      ],
    },
    verifyEmail: {
      url: '/account/verify-email',
      excludeModules: ['ProgressBar'],
      title: 'pageRoutes.accountVerifyEmail',
      component: 'VerifyEmail',
    },
    accountLogout: {
      name: 'accountLogout',
      url: '/account/logout',
      excludeModules: ['ProgressBar'],
      title: 'pageRoutes.accountLogout',
    },
    notFound: {
      name: 'notFound',
      url: '*',
      excludeModules: ['ProgressBar'],
      title: 'pageRoutes.notFound',
      component: 'NotFound',
    },
    systemMaintenance: {
      name: 'systemMaintenance',
      url: '/maintenance',
      title: 'pageRoutes.systemMaintenance',
      component: 'SystemMaintenance',
    },
  },
  modals: {
    UploadDocModal: {},
    ShareholderDetails: {},
    LinkAccountDetails: {},
    GeneralError: {
      excludeModules: ['ProgressBar'],
    },
  },
  steps: [
    { stepName: stepsNames.consent, component: 'Consent' },
    { stepName: stepsNames.businessType, component: 'BusinessType' },
    { stepName: stepsNames.aboutYourBusiness, component: 'AboutYourBusiness' },
    { stepName: stepsNames.directors, component: 'Directors' },
    { stepName: stepsNames.aboutYou, component: 'AboutYou' },
    { stepName: stepsNames.previousAddress, component: 'PreviousAddress' },
    { stepName: stepsNames.beneficialOwners, component: 'BeneficialOwners' },
    { stepName: stepsNames.financialDocuments, component: 'FinancialInformation' },
    { stepName: stepsNames.sliders, component: 'Sliders', retrieveCaseBeforeLoad: true },
    { stepName: stepsNames.referredPage, component: 'ThankYou' },
    { stepName: stepsNames.thankYou, component: 'ThankYou' },
    { stepName: stepsNames.congratulations, component: 'Congratulations', retrieveCaseBeforeLoad: true },
    { stepName: stepsNames.rejectedPage, component: 'RejectCancel' },
    { stepName: stepsNames.cancel, component: 'RejectCancel' },
    { stepName: stepsNames.selfDeclaredFinancialDetails, component: 'SelfDeclaredFinancialDetails' },
    { stepName: stepsNames.financialInformation, component: 'FinancialInformation' },
    { stepName: stepsNames.error, component: 'RejectCancel' },
    { stepName: stepsNames.idv, component: 'Idv' },
    { stepName: stepsNames.idvSuccess, component: 'IDVSuccess' },
    { stepName: stepsNames.idvError, component: 'IDVError' },
    { stepName: stepsNames.documentSign, component: 'DocumentSign' },
    { stepName: stepsNames.bankAccountSelection, component: 'BankAccountSelection' },
    { stepName: stepsNames.eligibilityRejection, component: 'RejectCancel' },
    { stepName: stepsNames.inviteContributors, component: 'InviteContributors' },
    { stepName: stepsNames.vatLinking, component: 'VatLinking' },
    { stepName: stepsNames.applicationSummary, component: 'ApplicationSummary' },
    { stepName: stepsNames.loanAgreement, component: 'LoanAgreement' },
    { stepName: stepsNames.caseExpiredMorning, component: 'RejectCancel' },
    { stepName: stepsNames.rlsEligibility, component: 'RlsEligibility' },
  ],
  theme,
  assets: {
    logo: {
      src: `${resourcesCdnBasePath}svg/BeBank_logo.svg`,
      alt: 'BeBank logo',
      height: '3.2rem',
    },
    icon: {
      src: `${resourcesCdnBasePath}svg/BeBank_icon.svg`,
      alt: 'BeBank icon',
    },
  },
  updateUserFields: {
    email: 'applicant.email',
    pw: 'applicant.pw',
  },
  widgetsConfig: {
    selectedDecisionUuid: {
      validationRules: 'required',
      customErrorMessage: {
        required: 'CHOOSE_AN_OFFER',
      },
    },
    loanAgreement: {
      validationRules: 'required',
      customErrorMessage: {
        required: 'READ_DOCUMENT_REQUIRED',
      },
    },
    applicant: {
      fullName: {
        firstName: {
          validationRules: 'required|min:2|max:100|regex:/^[a-zA-Z -]*$/',
          customErrorMessage: {
            required: 'REQUIRED',
            min: 'MIN',
            max: 'MAX',
            regex: 'INPUT',
          },
        },
        middleName: {
          validationRules: 'max:100|regex:/^[a-zA-Z -]*$/',
          customErrorMessage: {
            max: 'MAX',
            regex: 'INPUT',
          },
        },
        lastName: {
          validationRules: 'required|min:1|max:100|regex:/^[a-zA-Z. -]*$/',
          customErrorMessage: {
            required: 'REQUIRED',
            min: 'MIN',
            max: 'MAX',
            regex: 'INPUT',
          },
        },
      },
      identifier: {
        validationRules: ['required', 'regex:/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/'],
        customErrorMessage: {
          required: 'REQUIRED',
          regex: 'SSNINPUT',
        },
        formattingOptions: {
          useRawValue: false,
          delimiter: '-',
          blocks: [3, 2, 4],
          numericOnly: true,
        },
        maskingOptions: {
          start: 0,
          end: 5,
          maskingSymbol: 'X',
          omitChars: ['-'],
        },
      },
      birthDate: {
        validationRules: `required|date|minAge:18|maxAge:118`,
        customErrorMessage: {
          date: 'DATE',
          required: 'REQUIRED',
          minAge: 'MIN_AGE',
          maxAge: 'MAX_AGE',
        },
        minAge: 18,
        maxAge: 118,
        format: locals.primaryDateFormat,
      },
      address: {
        validationRules: 'required|address',
        customErrorMessage: {
          required: 'REQUIRED',
          address: 'ADDRESS',
        },
        shouldOpenAddressListPopup: true,
        showDropdownFooter: false,
        policyField: 'loanee.typeOfBusiness',
        lastLineManualEditing: true,
        checkAlreadyMatch: true,
        manualAddress: {
          line1: {
            validationRules: 'required|max:100',
            customErrorMessage: {
              required: 'REQUIRED_LINE1',
            },
          },
          line2: { validationRules: 'max:999' },
          line3: { validationRules: 'max:999' },
          town: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED_TOWN',
            },
          },
          postcode: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED_POSTCODE',
            },
          },
          state: {
            validationRules: 'max:999',
            enabled: false,
            values: {},
          },
          country: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED_COUNTRY',
            },
            enabled: false,
            values: [
              {
                value: 'GB',
              },
            ],
          },
        },
      },
      previousAddress: {
        validationRules: 'required|address',
        customErrorMessage: {
          required: 'REQUIRED',
          address: 'ADDRESS',
        },
        policyField: 'loanee.typeOfBusiness',
        lastLineManualEditing: true,
        checkAlreadyMatch: true,
        manualAddress: {
          line1: {
            validationRules: 'required|max:100',
            customErrorMessage: {
              required: 'REQUIRED_LINE1',
            },
          },
          line2: { validationRules: 'max:999' },
          line3: { validationRules: 'max:999' },
          town: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED_TOWN',
            },
          },
          postcode: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED_POSTCODE',
            },
          },
          country: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED_COUNTRY',
            },
          },
        },
      },
      email: {
        validationRules: 'required|min:3|email',
        customErrorMessage: {
          email: 'VALID_EMAIL',
          required: 'REQUIRED',
          min: 'MIN',
        },
        inputProps: { autoComplete: 'off', type: 'email' },
      },
      mobile: {
        maxSendCodeAttempts: 3,
        validationRules: 'required|mobile',
        blockUserOnTooManyFailedAttempts: false,
        customErrorMessage: {
          required: 'REQUIRED',
          mobile: 'REQUIRED',
        },
        phone: {
          validationRules: 'required|max:15|phone:GB',
          customErrorMessage: {
            required: 'REQUIRED',
            max: 'MAX',
            phone: 'PHONE_VALID',
          },
        },
        code: {
          validationRules: 'min:6|max:6|required',
          customErrorMessage: {
            required: 'REQUIRED',
            min: 'MIN',
            max: 'MAX',
          },
          formattingOptions: {
            blocks: [6],
            numericOnly: true,
          },
          inputProps: {
            type: 'number',
          },
        },
      },
      pastAddresses: [
        {
          validationRules: 'required|address',
          customErrorMessage: {
            required: 'REQUIRED',
            address: 'ADDRESS',
          },
          shouldOpenAddressListPopup: true,
          showDropdownFooter: false,
          policyField: 'loanee.typeOfBusiness',
          lastLineManualEditing: true,
          manualAddress: {
            line1: {
              validationRules: 'required|max:100',
              customErrorMessage: {
                required: 'REQUIRED_LINE1',
              },
            },
            line2: { validationRules: 'max:999' },
            line3: { validationRules: 'max:999' },
            town: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_TOWN',
              },
            },
            postcode: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_POSTCODE',
              },
            },
            country: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_COUNTRY',
              },
              inputProps: { disabled: true },
            },
          },
        },
        {
          validationRules: 'address',
          customErrorMessage: {
            required: 'REQUIRED',
            address: 'ADDRESS',
          },
          shouldOpenAddressListPopup: true,
          showDropdownFooter: false,
          policyField: 'loanee.typeOfBusiness',
          lastLineManualEditing: true,
          manualAddress: {
            line1: {
              validationRules: 'required|max:100',
              customErrorMessage: {
                required: 'REQUIRED_LINE1',
              },
            },
            line2: { validationRules: 'max:999' },
            line3: { validationRules: 'max:999' },
            town: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_TOWN',
              },
            },
            postcode: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_POSTCODE',
              },
            },
            country: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_COUNTRY',
              },
              inputProps: { disabled: true },
            },
          },
        },
        {
          validationRules: 'address',
          customErrorMessage: {
            address: 'ADDRESS',
          },
          shouldOpenAddressListPopup: true,
          showDropdownFooter: false,
          policyField: 'loanee.typeOfBusiness',
          lastLineManualEditing: true,
          manualAddress: {
            line1: {
              validationRules: 'required|max:100',
              customErrorMessage: {
                required: 'REQUIRED_LINE1',
              },
            },
            line2: { validationRules: 'max:999' },
            line3: { validationRules: 'max:999' },
            town: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_TOWN',
              },
            },
            postcode: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_POSTCODE',
              },
            },
            country: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_COUNTRY',
              },
              inputProps: { disabled: true },
            },
          },
        },
      ],
      idVerification: {
        desktop: {
          hidePopupOnFinish: false,
          checkStatusInterval: { seconds: 5 },
          phone: {
            validationRules: 'required|max:15|phone:GB',
            customErrorMessage: {
              regex: 'PHONE_VALID',
              required: 'REQUIRED',
              max: 'MAX',
              phone: 'PHONE_VALID',
            },
          },
        },
        mobile: {
          requestPhotos: ['front', 'back', 'selfie'],
          requestPhotosIcons: {
            front: {
              src: '/assets/svg/id_card_circle.svg',
            },
            back: {
              src: '/assets/svg/id_card_circle.svg',
            },
            selfie: {
              src: '/assets/svg/selfie_circle.svg',
            },
          },
          disableWelcomeScreen: false,
          maxFileSize: 5242880,
          allowFileExtensions: ['png', 'jpg', 'tif', 'tiff', 'jpeg'],
          enableCheckLiveness: true,
          autoVerify: false,
          // TODO: Refine values according to: CORE-5974
          selfie: {
            captureType: 'manual',
            customChecks: {
              framesToValidate: 3,
              shouldCheckFaceStatus: true,
              faceStatus: 1,
              shouldCheckFaceDistanceStatus: false,
              faceDistanceStatus: 1,
            },
          },
          card: {
            captureType: 'manual',
            customChecks: {
              framesToValidate: 1,
              shouldCheckIdStatus: true,
              idStatus: 1,
              shouldCheckBlurStatus: false,
              blurStatus: 1,
              shouldCheckReflectionStatus: false,
              reflectionStatus: 1,
              shouldCheckDarkStatus: false,
              darkStatus: 1,
              shouldCheckDocDistanceStatus: false,
              docDistanceStatus: 1,
            },
          },
          retry: {
            icon: {
              src: '/assets/svg/err-circle-icon.svg',
            },
          },
          finish: {
            icon: {
              src: '/assets/svg/success-circle-icon.svg',
            },
          },
        },
      },
      gender: {
        validationRules: 'required',
        values: [
          {
            value: 'm',
            icon: 'https://code.ezbob.com/app-ezx/greenlend/images/male_icon.svg',
          },
          {
            value: 'f',
            icon: 'https://code.ezbob.com/app-ezx/greenlend/images/female_icon.svg',
          },
        ],
      },
    },
    loanee: {
      typeOfBusiness: {
        validationRules: 'required',
        values: typeOfBusiness,
        customErrorMessage: {
          required: 'REQUIRED',
        },
      },
      companyNameNumber: {
        lastLineManualEditing: true,
        validationRules: 'required|min:1',
        customErrorMessage: {
          required: 'VALID_COMPANY',
          min: 'MIN',
          regex: 'INPUT',
        },
        manualNameNumber: {
          name: {
            validationRules: 'required|min:2|max:255',
            customErrorMessage: {
              required: 'REQUIRED',
              min: 'MIN',
              max: 'MAX',
            },
          },
          identifier: {
            validationRules: 'required|alpha_num|min:8|max:8',
            customErrorMessage: {
              required: 'REQUIRED',
              alpha_num: 'INPUT',
              min: 'MIN',
              max: 'MAX',
            },
          },
        },
      },
      name: {
        validationRules: 'required|min:2|max:255',
        customErrorMessage: {
          required: 'VALID_COMPANY',
          min: 'MIN',
          max: 'MAX',
        },
      },
      identifier: {
        validationRules: 'required',
        customErrorMessage: {
          required: 'VALID_COMPANY',
        },
      },
      companyType: {
        validationRules: 'required|includes:ltd,limited|min:2|max:255',
        customErrorMessage: {
          required: 'VALID_COMPANY',
          min: 'MIN',
          max: 'MAX',
          includes: 'VALID_COMPANY_TYPE',
        },
      },
      tradingActivity: {
        validationRules: 'required|string',
        customErrorMessage: {
          required: 'REQUIRED',
          string: 'SELECT_FROM_LIST',
        },
        values: tradingActivityList,
      },
      businessAddress: {
        LIMITED_COMPANY: {
          validationRules: 'required|address',
          customErrorMessage: {
            required: 'REQUIRED',
            address: 'ADDRESS',
          },
          shouldOpenAddressListPopup: true,
          showDropdownFooter: false,
          lastLineManualEditing: true,
          checkAlreadyMatch: true,
          policyField: 'loanee.typeOfBusiness',
          manualAddress: {
            line1: {
              validationRules: 'required|max:100',
              customErrorMessage: {
                required: 'REQUIRED_LINE1',
              },
            },
            line2: { validationRules: 'max:999' },
            line3: { validationRules: 'max:999' },
            town: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_TOWN',
              },
            },
            postcode: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_POSTCODE',
              },
            },
            country: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_COUNTRY',
              },
              inputProps: { disabled: true },
            },
          },
        },
        SOLE_TRADER: {
          validationRules: 'required|address',
          customErrorMessage: {
            required: 'REQUIRED',
            address: 'ADDRESS',
          },
          lastLineManualEditing: true,
          checkAlreadyMatch: true,
          shouldOpenAddressListPopup: true,
          showDropdownFooter: false,
          policyField: 'loanee.typeOfBusiness',
          manualAddress: {
            line1: {
              validationRules: 'required|max:100',
              customErrorMessage: {
                required: 'REQUIRED_LINE1',
              },
            },
            line2: { validationRules: 'max:999' },
            line3: { validationRules: 'max:999' },
            town: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_TOWN',
              },
            },
            postcode: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_POSTCODE',
              },
            },
            country: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_COUNTRY',
              },
              inputProps: { disabled: true },
            },
          },
        },
      },
      annualRevenue: {
        LIMITED_COMPANY: {
          validationRules: 'required|numeric|min:2|max:2000000',
          customErrorMessage: {
            required: 'REQUIRED',
            min: 'MIN_AMOUNT',
            max: 'MAX_AMOUNT',
          },
          formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
          inputProps: { type: 'currency' },
        },
        SOLE_TRADER: {
          validationRules: 'required|numeric|min:1|max:999999999',
          customErrorMessage: {
            required: 'REQUIRED',
            max: 'MAX_AMOUNT',
          },
          formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
          inputProps: { type: 'currency' },
        },
      },
      annualNetProfitBeforeTax: {
        LIMITED_COMPANY: {
          validationRules: 'required|numeric|min:1',
          customErrorMessage: {
            required: 'REQUIRED',
            numeric: 'NUMERIC',
            min: 'MIN_AMOUNT',
            max: 'MAX_AMOUNT',
          },
          inputProps: { type: 'currency' },
          formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
        },
        SOLE_TRADER: {
          validationRules: 'required|numeric',
          customErrorMessage: {
            required: 'REQUIRED',
            numeric: 'NUMERIC',
            min: 'MIN_AMOUNT',
            max: 'MAX_AMOUNT',
          },
          inputProps: { type: 'currency' },
          formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
        },
      },
      monthlyDebtRepayments: {
        LIMITED_COMPANY: {
          validationRules: 'numeric',
          customErrorMessage: {
            numeric: 'NUMERIC',
          },
          inputProps: { type: 'currency' },
          formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
        },
        SOLE_TRADER: {
          validationRules: 'required|numeric|max:999999999',
          customErrorMessage: {
            required: 'REQUIRED',
            numeric: 'NUMERIC',
            max: 'MAX_AMOUNT',
          },
          inputProps: { type: 'currency' },
          formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
        },
      },
      annualDrawings: {
        validationRules: 'required|numeric|max:999999999',
        customErrorMessage: {
          required: 'REQUIRED',
          numeric: 'NUMERIC',
          max: 'MAX_AMOUNT',
        },
        inputProps: { type: 'currency' },
        formattingOptions: { ...currencyFormattingOptions, numeralDecimalScale: 0 },
      },
    },
    personsSignificantControl: {
      type: 'DIRECTORS',
      includeCompanies: false,
    },
    custom: {
      vrn: {
        validationRules: 'required|digits:9',
        customErrorMessage: {
          required: 'REQUIRED',
          digits: 'DIGITS',
        },
        formattingOptions: { ...positiveIntegerFormattingOptions },
      },
      consentCheckbox: {},
    },
    loanRequest: {
      monthlyRepaymentsPosition: 'top',
      showTermsSlider: true,
      showMonthlyPayment: true,
      useServerCalculation: true,
      serverCalculation: {
        policy: 'INDICATIVE_PRICING_OFFER##!!@@expresslimitedaffordability',
      },
      loanPurpose: {
        LIMITED_COMPANY: {
          validationRules: 'required',
          customErrorMessage: {
            required: 'REQUIRED',
          },
          values: loanPurposeList_LIMITED_COMPANY,
        },
        SOLE_TRADER: {
          validationRules: 'required',
          customErrorMessage: {
            required: 'REQUIRED',
          },
          values: loanPurposeList_SOLE_TRADER,
        },
      },
      otherLoanPurpose: {
        validationRules: 'required|regex:/^[a-zA-Z -]*$/',
        customErrorMessage: {
          required: 'REQUIRED',
          regex: 'INPUT',
        },
      },
      amount: {
        LIMITED_COMPANY: {
          defaultValue: 100000,
          min: 25000,
          max: 150000,
          step: 1000,
          valueFieldModifiers: false,
          sliderModifiers: true,
          formattingOptions: {
            ...currencyFormattingOptions,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          },
        },
        SOLE_TRADER: {
          defaultValue: 100000,
          min: 2500,
          max: 200000,
          step: 1000,
          valueFieldModifiers: false,
          sliderModifiers: true,
          formattingOptions: {
            ...currencyFormattingOptions,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          },
        },
      },
      term: {
        LIMITED_COMPANY: {
          defaultValue: 24,
          min: 6,
          max: 72,
          step: 6,
          valueFieldModifiers: false,
          sliderModifiers: true,
        },
        SOLE_TRADER: {
          defaultValue: 24,
          min: 6,
          max: 36,
          step: 6,
          valueFieldModifiers: false,
          sliderModifiers: true,
        },
      },
      annualInterestRate: {
        LIMITED_COMPANY: {
          defaultValue: 6.4,
        },
        SOLE_TRADER: {
          defaultValue: 6.4,
        },
      },
      apr: {
        LIMITED_COMPANY: {
          defaultValue: 6.59,
          useCustomPercentageFormat: true,
          formattingOptions: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        SOLE_TRADER: {
          defaultValue: 6.59,
          useCustomPercentageFormat: false,
          formattingOptions: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          },
        },
      },
    },
    financialDocuments: {
      vatReports: {
        enabled: true,
        maxFiles: 30,
        maxSize: 52428800, // 50 MB
        fileExtensions: [
          { value: 'pdf', active: true },
          { value: 'doc', active: true },
          { value: 'docx', active: true },
          { value: 'odt', active: true },
          { value: 'rtf', active: true },
          { value: 'ppt', active: true },
          { value: 'pptx', active: true },
          { value: 'odp', active: true },
          { value: 'xls', active: true },
          { value: 'xlsx', active: true },
          { value: 'ods', active: true },
          { value: 'txt', active: true },
          { value: 'csv', active: true },
          { value: 'xml', active: true },
          { value: 'xhtml', active: true },
          { value: 'mht', active: true },
          { value: 'msg', active: true },
          { value: 'eml', active: true },
          { value: 'bpm', active: true },
          { value: 'jpg', active: true },
          { value: 'jpeg', active: true },
          { value: 'png', active: true },
        ],
        type: 'VAT',
        displayFields: ['fileName'],
        allowDelete: true,
      },
      bankStatements: {
        enabled: true,
        maxFiles: 30,
        maxSize: 52428800, // 50 MB
        fileExtensions: [
          { value: 'pdf', active: true },
          { value: 'doc', active: true },
          { value: 'docx', active: true },
          { value: 'odt', active: true },
          { value: 'rtf', active: true },
          { value: 'ppt', active: true },
          { value: 'pptx', active: true },
          { value: 'odp', active: true },
          { value: 'xls', active: true },
          { value: 'xlsx', active: true },
          { value: 'ods', active: true },
          { value: 'txt', active: true },
          { value: 'csv', active: true },
          { value: 'xml', active: true },
          { value: 'xhtml', active: true },
          { value: 'mht', active: true },
          { value: 'msg', active: true },
          { value: 'eml', active: true },
          { value: 'bpm', active: true },
          { value: 'jpg', active: true },
          { value: 'jpeg', active: true },
          { value: 'png', active: true },
        ],
        type: 'BANK',
        displayFields: ['fileName'],
        allowDelete: true,
      },
    },
    _contributors: {
      consent: {
        defaultValue: false,
        validationRules: 'accepted',
        customErrorMessage: {
          accepted: 'REQUIRED',
        },
      },
      invitee: {
        fullName: {
          firstName: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED',
            },
          },
          lastName: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED',
            },
          },
        },
        email: {
          validationRules: 'required',
          customErrorMessage: {
            required: 'REQUIRED',
          },
        },
        mobile: {
          phone: {
            validationRules: 'required',
            customErrorMessage: {
              required: 'REQUIRED',
            },
          },
        },
      },
    },
    contributors: [
      {
        fullName: {
          firstName: {
            validationRules: 'required|min:2|max:100|regex:/^[a-zA-Z -]*$/',
            customErrorMessage: {
              required: 'REQUIRED',
              min: 'MIN',
              max: 'MAX',
              regex: 'INPUT',
            },
          },
          lastName: {
            validationRules: 'required|min:1|max:100|regex:/^[a-zA-Z. -]*$/',
            customErrorMessage: {
              required: 'REQUIRED',
              min: 'MIN',
              max: 'MAX',
              regex: 'INPUT',
            },
          },
        },
        identifier: {
          validationRules: 'required',
          customErrorMessage: {
            required: 'REQUIRED',
          },
        },
        email: {
          validationRules: 'required|email',
          customErrorMessage: {
            email: 'VALID_EMAIL',
            required: 'REQUIRED',
          },
          inputProps: {
            autoComplete: 'off',
            type: 'email',
          },
        },
        phoneNumber: {
          validationRules: 'required|max:15',
          customErrorMessage: {
            required: 'REQUIRED',
            max: 'MAX',
          },
        },
      },
    ],
    officers: {
      showNextButton: false,
      fraudIncludeThisIsMeShareholders: false,
      fraudIncludeNotActiveShareholders: false,
      enableWasEditSkipped: true,
      type: 'ALL',
      includeCompanies: false,
      personDetails: {
        address: {
          validationRules: 'required|address',
          customErrorMessage: {
            required: 'REQUIRED',
            address: 'ADDRESS',
          },
          policyField: 'loanee.typeOfBusiness',
          lastLineManualEditing: true,
          manualAddress: {
            line1: {
              validationRules: 'required|max:100',
              customErrorMessage: {
                required: 'REQUIRED_LINE1',
              },
            },
            line2: { validationRules: 'max:999' },
            line3: { validationRules: 'max:999' },
            town: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_TOWN',
              },
            },
            postcode: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_POSTCODE',
              },
            },
            country: {
              validationRules: 'required',
              customErrorMessage: {
                required: 'REQUIRED_COUNTRY',
              },
              inputProps: { disabled: true },
            },
          },
        },

        identifier: {
          validationRules: ['required', 'regex:/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/'],
          customErrorMessage: {
            required: 'REQUIRED',
            regex: 'SSNINPUT',
          },
          formattingOptions: {
            useRawValue: false,
            delimiter: '-',
            blocks: [3, 2, 4],
            numericOnly: true,
          },
          maskingOptions: {
            start: 0,
            end: 5,
            maskingSymbol: 'X',
            omitChars: ['-'],
          },
        },

        birthDate: {
          validationRules: `required|minAge:18|maxAge:118`,
          customErrorMessage: {
            date: 'DATE',
            required: 'REQUIRED',
            minAge: 'MIN_AGE',
            maxAge: 'MAX_AGE',
          },
          minAge: 18,
          maxAge: 118,
        },

        email: {
          validationRules: 'required|email',
          customErrorMessage: {
            email: 'VALID_EMAIL',
            required: 'REQUIRED',
          },
          inputProps: { autoComplete: 'off', type: 'email' },
        },

        fullName: {
          firstName: {
            validationRules: "required|min:2|max:100|regex:/^[a-zA-Z '-.]*$/",
            customErrorMessage: {
              required: 'REQUIRED',
              min: 'MIN',
              max: 'MAX',
              regex: 'INPUT',
            },
          },
          middleName: {
            validationRules: "max:100|regex:/^[a-zA-Z '-]*$/",
            customErrorMessage: {
              max: 'MAX',
              regex: 'INPUT',
            },
          },
          lastName: {
            validationRules: "required|min:1|max:100|regex:/^[a-zA-Z '-.]*$/",
            customErrorMessage: {
              required: 'REQUIRED',
              min: 'MIN',
              max: 'MAX',
              regex: 'INPUT',
            },
          },
        },
      },
    },
    meta: {
      consents: {
        identity: {
          values: [{ value: 'yes' }, { value: 'no' }],
        },
        termsAndConditions: {
          validationRules: 'accepted',
          customErrorMessage: {
            accepted: 'REQUIRED',
          },
        },
        businessLoanAgreement: {
          validationRules: 'accepted',
          customErrorMessage: {
            accepted: 'REQUIRED',
          },
        },
        personalGuarantee: {
          validationRules: 'accepted',
          customErrorMessage: {
            checkbox: 'REQUIRED',
          },
        },
      },
    },
    customerLogin: {
      showClosedApplications: true,
      allowNewApplication: true,
      allowCancelApplication: true,
      allowIncorrectInformation: false,
      summaryFields: [
        'loanee.typeOfBusiness',
        'loanee.name',
        'loanRequest.loanPurpose',
        'loanRequest.amount',
        'loanRequest.term',
        'applicant.fullName.firstName',
        'applicant.fullName.middleName',
        'applicant.fullName.lastName',
        'applicant.birthDate',
        'applicant.address',
        'applicant.pastAddresses',
      ],
      username: {
        validationRules: 'required|email',
        customErrorMessage: {
          email: 'VALID_EMAIL',
          required: 'REQUIRED',
        },
        inputProps: {
          autoComplete: 'false',
        },
      },
      phone: {
        validationRules: 'required|max:15|phone:GB',
        customErrorMessage: {
          required: 'REQUIRED',
          max: 'MAX',
          phone: 'PHONE_VALID',
        },
      },
      code: {
        validationRules: 'required|min:6|max:6',
        customErrorMessage: {
          required: 'REQUIRED',
          min: 'MIN',
          max: 'MAX',
        },
        formattingOptions: {
          blocks: [6],
          numericOnly: true,
        },
      },
      loanRequest: {
        amount: {
          formattingOptions: {
            ...currencyFormattingOptions,
          },
          type: 'number',
        },
      },
    },
    applicationSummary: {
      loanAmount: {
        formattingOptions: {
          ...currencyFormattingOptions,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        },
      },
    },
    sliders: {
      offerAmount: {
        formattingOptions: {
          ...currencyFormattingOptions,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        },
      },
      monthlyPayment: {
        formattingOptions: {
          ...currencyFormattingOptions,
        },
      },
    },
    congratulations: {
      monthlyPayment: {
        formattingOptions: {
          numeral: locals.numeral,
          numeralPositiveOnly: locals.numeralPositiveOnly,
          maximumFractionDigits: locals.maximumFractionDigits,
          minimumFractionDigits: locals.minimumFractionDigits,
          numeralIntegerScale: locals.numeralIntegerScale,
          numeralDecimalMark: locals.numeralDecimalMark,
          delimiter: locals.delimiter,
        },
      },
      approvedForSum: {
        formattingOptions: {
          numeral: locals.numeral,
          numeralPositiveOnly: locals.numeralPositiveOnly,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          numeralIntegerScale: locals.numeralIntegerScale,
          numeralDecimalMark: locals.numeralDecimalMark,
          delimiter: locals.delimiter,
        },
      },
      totalAmountPayable: {
        formattingOptions: {
          numeral: locals.numeral,
          numeralPositiveOnly: locals.numeralPositiveOnly,
          maximumFractionDigits: locals.maximumFractionDigits,
          minimumFractionDigits: locals.minimumFractionDigits,
          numeralIntegerScale: locals.numeralIntegerScale,
          numeralDecimalMark: locals.numeralDecimalMark,
          delimiter: locals.delimiter,
        },
      },
      interestAmount: {
        formattingOptions: {
          numeral: locals.numeral,
          numeralPositiveOnly: locals.numeralPositiveOnly,
          maximumFractionDigits: locals.maximumFractionDigits,
          minimumFractionDigits: locals.minimumFractionDigits,
          numeralIntegerScale: locals.numeralIntegerScale,
          numeralDecimalMark: locals.numeralDecimalMark,
          delimiter: locals.delimiter,
        },
      },
      fee: {
        formattingOptions: {
          numeral: locals.numeral,
          numeralPositiveOnly: locals.numeralPositiveOnly,
          maximumFractionDigits: locals.maximumFractionDigits,
          minimumFractionDigits: locals.minimumFractionDigits,
          numeralIntegerScale: locals.numeralIntegerScale,
          numeralDecimalMark: locals.numeralDecimalMark,
          delimiter: locals.delimiter,
        },
      },
    },
    otp: {
      channel: {
        defaultValue: 'SMS',
        values: [{ value: 'SMS' }],
      },
      code: {
        validationRules: 'required',
        customErrorMessage: {
          required: 'REQUIRED',
          digits: 'DIGITS',
        },
        resendTimeoutDuration: {
          seconds: 30,
        },
        inputProps: {
          type: 'number',
          autocomplete: 'one-time-code',
        },
      },
      screenWrapper: {
        backgroundImage: {
          desktop: '',
          mobile: '',
        },
      },
    },
  },
  googleTagManager: {
    gtmId: 'GTM-WS8FGWH',
    allowedTracking: {
      VirtualPageview: ['Step', 'Page'],
      InputTracking: ['onFocus', 'onBlur', 'checkboxClick'],
      ButtonTracking: ['onClick', 'tooltipClick'],
    },
  },
  languages: {
    fallbackLanguage: 'en-GB',
    languageList: {
      'en-GB': { translation: enLanguage },
    },
  },
  environments: {
    appHost: 'app.ezbob.com',
  },
  custom: {
    loadingText: '',
    resourcesCdnBasePath: 'https://code.ezbob.com/app-ezx/resources/',
    applicationSummary: {
      fields: [
        { name: 'companyName' },
        { name: 'registrationNumber' },
        { name: 'registeredAddress', formatter: 'formatAddress' },
        { name: 'primaryApplicant', formatter: 'formatFullName' },
        { name: 'purposeOfTheLoan', formatter: 'formatLoanPurpose' },
        { name: 'loanAmount', type: 'currency' },
        { name: 'loanTerm', formatter: 'formatMonths' },
      ],
    },
    consent: {
      variant: 'checkbox', // or ['checkbox', 'button']
      useOfPersonalDatalink: 'https://ui.demo.ezbob.com/use-of-personal-data',
      bgMobile: `${resourcesCdnBasePath}images/building_bg_mobile.png`,
      bgDesktop: `${resourcesCdnBasePath}images/building_bg_desktop.png`,
    },
    businessType: {
      allowedBusinessType: 'both', // all possible options: "SOLE_TRADER", "LIMITED_COMPANY", "both"
    },
    documentSign: {
      maxWidth: '100.6rem',
      logo: {
        src: `${resourcesCdnBasePath}svg/positive_check.svg`,
        alt: 'thank you logo',
      },
    },
    sliders: {
      showLoanPurpose: false,
      isCancelCaseAvailable: false,
    },
    loanAgreement: {
      isCancelCaseAvailable: false,
    },
    referredPage: {
      logo: {
        src: `${resourcesCdnBasePath}svg/thank_you_logo.svg`,
        alt: 'thank you logo',
      },
      backgroundImage: `${resourcesCdnBasePath}images/abstract-digital-background.png`,
      pageActions: [],
    },
    thankYou: {
      logo: {
        src: `${resourcesCdnBasePath}svg/thank_you_logo.svg`,
        alt: 'thank you logo',
      },
      backgroundImage: `${resourcesCdnBasePath}images/abstract-digital-background.png`,
      pageActions: [],
    },
    rejectedPage: {
      logo: {
        src: `${resourcesCdnBasePath}svg/reject.svg`,
        alt: 'reject',
      },
      pageActions: [],
    },
    cancel: {
      logo: {
        src: '',
        alt: '',
      },
      pageActions: [],
    },
    eligibilityRejection: {
      logo: {
        src: `${resourcesCdnBasePath}svg/rejectedPage.svg`,
        alt: 'reject',
      },
    },
    aboutYou: {
      showPersonalGuaranteeCheckbox: true,
      areNameFieldsDisabled: true,
      syncApplicantWithShareholders: true,
    },
    idvConsent: {
      logo: {
        src: `${resourcesCdnBasePath}svg/fingerprint.svg`,
        alt: 'fingerprint',
      },
      type: 'radio', // ['checkbox', 'radio', '']
    },
    help: {
      type: helpType.modal,
      isHidden: false,
    },
    financialInformation: {
      maxWidth: '100.6rem',
      allowSkip: false,
      confirmSkip: true,
      openBankingSkipStep: {
        soleTrader: 'selfDeclaredFinancialDetails',
        limitedCompany: 'financialDocuments',
      },
    },
    vatLinking: {
      allowSkip: true,
      service: 'hmrc',
      skipStep: {
        soleTrader: 'selfDeclaredFinancialDetails',
        limitedCompany: 'financialDocuments',
      },
    },
    verifyEmail: {
      verified: {
        logo: {
          src: `${resourcesCdnBasePath}svg/thankyou_circle.svg`,
          alt: 'verify',
        },
      },
      notVerified: {
        logo: {
          src: `${resourcesCdnBasePath}svg/thankyou_circle.svg`,
          alt: 'verify',
        },
      },
    },
  },
};

export default cfg;
