import { css, styled } from '@ezbobdev/ui-widgets-ezbob';

export const UlStyled = styled('ul')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: 0;
    margin: 0;

    li {
      display: flex;
      gap: ${theme.spacing(1)};
      align-items: center;

      & img {
        width: ${theme.spacing(3)};
      }
    }
  `}
`;
