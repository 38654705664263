import React, { useContext } from 'react';

import { MainContext, useTranslation } from '@ezbobdev/ui-framework';
import { MaterialTypography } from '@ezbobdev/ui-widgets-ezbob';

import { UlStyled } from './List.styled';

const ListItem = ({ text, icon }) => {
  const { t } = useTranslation();
  const { actions } = useContext(MainContext);
  const listIconSrc = icon?.src ?? actions.settings.getUserConfigProp(`assets.checkIcon.src`);
  const listIconAlt = icon?.alt ?? t('assets.checkIcon.alt');

  return (
    <li className="ListItem">
      <img className="ListItemImage" alt={listIconAlt} src={listIconSrc} />
      <MaterialTypography>{text}</MaterialTypography>
    </li>
  );
};

const List = ({ textArray }) => (
  <UlStyled>
    {textArray.map((el) => (
      <ListItem key={el} text={el.text} icon={el.icon} />
    ))}
  </UlStyled>
);

export default List;
